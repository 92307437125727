import { InjectionKey } from 'vue'
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import { existResponse } from '@/utils/zod'
import {
  Notification,
  NotificationSummary,
  getNotificationResponse,
  GetNotificationResponse,
  getNotificationSummaryResponse,
  GetNotificationSummaryResponse,
  patchNotificationReadResponse,
  PatchNotificationReadResponse,
  patchNotificationAllReadResponse,
  PatchNotificationAllReadResponse,
  SUBJECT_TYPE,
} from '@/models/notification'

// modules
import { ValueOf } from '@/utils/types/types'

export type NotificationStateType = {
  isFetching: boolean
  notifications: Notification[]
  summary: NotificationSummary | null
  offset: number
  limit: number
  page: number
  listTotal: number
}

export const useNotification = () => {
  const repositoryFactory = useRepositoryFactory()
  const roomRepository = repositoryFactory.get('notification')

  const state = reactive<NotificationStateType>({
    isFetching: false,
    notifications: [],
    summary: null,
    offset: 0,
    limit: 20,
    page: 0,
    // NOTE: 一覧表示用の総数。変数名にlistを付けているのは、サマリー総数と区別を付ける目的
    listTotal: 0,
  })

  const total = computed<number>(() => {
    if (!state.summary) {
      return 0
    }
    return (
      state.summary.followCount +
      state.summary.likeCount +
      state.summary.messageCount
    )
  })

  const totalUnread = computed<number>(() => {
    if (!state.summary) {
      return 0
    }
    return (
      state.summary.followUnreadCount +
      state.summary.likeUnreadCount +
      state.summary.messageUnreadCount
    )
  })

  const existNewNotification = computed(() => totalUnread.value > 0)

  /**
   * 通知一覧取得
   **/
  const getNotifications = async (
    limit?: number,
    offset?: number
  ): Promise<Notification[]> => {
    state.limit = limit || state.limit
    state.offset = offset || state.offset
    const response = await roomRepository.get.getNotifications({
      limit: state.limit,
      offset: state.offset,
    })

    if (
      !existResponse<GetNotificationResponse>(getNotificationResponse, response)
    ) {
      return []
    }

    state.listTotal = response.notificationCount
    state.notifications = response.notifications

    return response.notifications
  }

  /**
   * 通知一覧をページネーションで取得
   **/
  const getNotificationsWithPager = async (
    page: number
  ): Promise<Notification[]> => {
    state.page = page
    state.offset = page * state.limit - state.limit

    return await getNotifications(state.limit, state.offset)
  }

  /**
   * 通知のサマリー取得
   **/
  const getNotificationSummary =
    async (): Promise<NotificationSummary | null> => {
      const { data } = await useAsyncData('getNotificationSummary', () =>
        roomRepository.get.getNotificationSummary()
      )

      if (
        !existResponse<GetNotificationSummaryResponse>(
          getNotificationSummaryResponse,
          data.value
        )
      ) {
        return null
      }

      state.summary = data.value.notification

      return data.value.notification
    }

  /**
   * 既読更新
   **/
  const patchNotificationRead = async (
    id: number
  ): Promise<PatchNotificationReadResponse | null> => {
    const { data } = await useAsyncData('patchNotificationRead', () =>
      roomRepository.patch.patchNotificationRead({ id })
    )

    if (
      !existResponse<PatchNotificationReadResponse>(
        patchNotificationReadResponse,
        data.value
      )
    ) {
      return null
    }

    // サマリー再取得
    try {
      await getNotificationSummary()
    } catch (e) {
      console.error('failed to fetch summary')
      console.error(e)
    }

    return data.value
  }

  /**
   * 既読一括更新
   **/
  const patchNotificationAllRead = async (
    subjectType: ValueOf<typeof SUBJECT_TYPE>
  ): Promise<PatchNotificationAllReadResponse | null> => {
    const { data } = await useAsyncData('patchNotificationRead', () =>
      roomRepository.patch.patchNotificationAllRead({ subjectType })
    )

    if (
      !existResponse<PatchNotificationAllReadResponse>(
        patchNotificationAllReadResponse,
        data.value
      )
    ) {
      return null
    }

    // サマリー再取得
    try {
      await getNotificationSummary()
    } catch (e) {
      console.error('failed to fetch summary')
      console.error(e)
    }

    return data.value
  }

  return {
    state: readonly(state),
    total,
    totalUnread,
    getNotifications,
    getNotificationsWithPager,
    getNotificationSummary,
    patchNotificationRead,
    existNewNotification,
    patchNotificationAllRead,
  }
}

export type NotificationComposable = ReturnType<typeof useNotification>

export const notificationInjectionKey: InjectionKey<NotificationComposable> =
  Symbol('notification')
