/* 通知API @see https://hikky.atlassian.net/wiki/spaces/BKS/pages/608501950 */

import { z } from 'zod'

export const SUBJECT_TYPE = {
  RELATIONSHIP: 'Relationship',
  WORLD_LIKE: 'WorldLike',
  DIRECT_MESSAGE: 'DirectMessage',
} as const

export const subjectType = z.enum([
  SUBJECT_TYPE.RELATIONSHIP, // フォロー
  SUBJECT_TYPE.WORLD_LIKE, // ワールドへのいいね
  SUBJECT_TYPE.DIRECT_MESSAGE, // 新規DM
])

export const user = z.object({
  id: z.number(),
  vketId: z.string(),
  name: z.string(),
  introduction: z.string().nullable(),
  icon: z.object({
    url: z.string().nullable(),
    circle: z.object({
      url: z.string().nullable(),
    }),
  }),
  topPageAvatarId: z.number().nullable(),
})

// フォローされたときの通知
export const followUser = user

export type FollowUser = z.infer<typeof followUser>

// ワールドへのいいね通知
export const worldLike = z.object({
  id: z.number(),
  userId: z.number(),
  worldSetId: z.number(),
  name: z.string(),
  worldId: z.string(),
  createdAt: z.union([z.instanceof(Date), z.string()]),
  updatedAt: z.union([z.instanceof(Date), z.string()]),
  user,
})

export type WorldLike = z.infer<typeof worldLike>

// メッセージの通知
export const directMessage = z.object({
  id: z.number(),
  userId: z.number(),
  roomId: z.number(),
  message: z.string(),
  name: z.string(),
  createdAt: z.union([z.instanceof(Date), z.string()]),
  updatedAt: z.union([z.instanceof(Date), z.string()]),
  user,
})

export type DirectMessage = z.infer<typeof directMessage>

export const event = z.object({
  id: z.number(),
  userId: z.number(),
  name: z.string(),
  description: z.string(),
  thumbnail: z.object({
    url: z.string().nullable(),
  }),
  worldName: z.string().nullable(),
  worldUrl: z.string(),
  startAt: z.string(),
  endAt: z.string(),
  user,
})

export type Event = z.infer<typeof event>

export const housingScene = z.object({
  id: z.number(),
  userId: z.number(),
  worldId: z.string(),
  name: z.string().nullable(),
  introduction: z.string().nullable(),
  thumbnail: z.object({
    url: z.string().nullable(),
  }),
  user,
})

export type HousingScene = z.infer<typeof housingScene>

export const eventLike = z.object({
  id: z.number(),
  userId: z.number(),
  eventId: z.number(),
  user,
})
export type EventLike = z.infer<typeof eventLike>

export const pushNotification = z.object({
  id: z.number(),
  title: z.string(),
  body: z.string(),
  link: z.string(),
})
export type PushNotification = z.infer<typeof pushNotification>

// お知らせ
export const notification = z.object({
  id: z.number(),
  subjectId: z.number(),
  userId: z.number(),
  isRead: z.boolean(),
  createdAt: z.union([z.instanceof(Date), z.string()]),
  updatedAt: z.union([z.instanceof(Date), z.string()]),
  followUser: followUser.optional().nullable(),
  worldLike: worldLike.optional().nullable(),
  directMessage: directMessage.optional().nullable(),
  event: event.optional().nullable(),
  housingScene: housingScene.optional().nullable(),
  eventLike: eventLike.optional().nullable(),
  pushNotification: pushNotification.optional().nullable(),
})

export type Notification = z.infer<typeof notification>

// サマリー
export const notificationSummary = z.object({
  followCount: z.number(),
  likeCount: z.number(),
  messageCount: z.number(),
  followUnreadCount: z.number(),
  likeUnreadCount: z.number(),
  messageUnreadCount: z.number(),
})

export type NotificationSummary = z.infer<typeof notificationSummary>

/* get */

export const getNotificationRequest = z.object({
  limit: z.number(),
  offset: z.number(),
  avatarId: z.number().optional(),
  messageId: z.number().optional(),
  roomId: z.number().optional(),
  subjectType: subjectType.optional(),
  unread: z.literal(true).optional(),
  vketId: z.string().optional(),
  worldSetId: z.number().optional(),
})

export type GetNotificationRequest = z.infer<typeof getNotificationRequest>

export const getNotificationResponse = z.object({
  notificationCount: z.number(),
  notifications: z.array(notification),
})

export type GetNotificationResponse = z.infer<typeof getNotificationResponse>

// サマリー

export const getNotificationSummaryResponse = z.object({
  notification: notificationSummary,
})

export type GetNotificationSummaryResponse = z.infer<
  typeof getNotificationSummaryResponse
>

/* patch */

export const patchNotificationReadRequest = z.object({
  id: z.number(),
})

export type PatchNotificationReadRequest = z.infer<
  typeof patchNotificationReadRequest
>

export const patchNotificationReadResponse = z.object({
  success: z.object({
    message: z.string(),
  }),
})

export type PatchNotificationReadResponse = z.infer<
  typeof patchNotificationReadResponse
>

// 一括既読

export const patchNotificationAllReadRequest = z.object({
  subjectType: subjectType.optional(),
})

export type PatchNotificationAllReadRequest = z.infer<
  typeof patchNotificationAllReadRequest
>

export const patchNotificationAllReadResponse = z.object({
  success: z.object({
    message: z.string(),
  }),
})

export type PatchNotificationAllReadResponse = z.infer<
  typeof patchNotificationReadResponse
>
